<template>
	<!-- 轮播图 -->
	<div class="cont">
		<img :src="img[0].src" class="img" />
		<div class="cont1">
			<div style="font-size: 36px">学蝉体测</div>
			<div style="font-size: 18px; margin-top: 20px" @click="dialogFormVisible = true">
				智能体测数据分析，提升机构的专业水平
			</div>
			<div class="freebtn">
				<div style="line-height: 38px;cursor: pointer;" @click="dialogFormVisible = true">
					免费试用
				</div>
			</div>
		</div>
		
	</div>
	<ApplyDialog v-model="dialogFormVisible" />

	<!-- 产品功能 -->
	<div class="cont_1">
		<div style="text-align: center; font-size: 36px">
			以国家标准为基础，结果更权威
		</div>
		<!-- <div class="line"></div> -->
		<div class="cont2">
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="el-txt">
						<div style="font-size: 28px">体质标准</div>
						<div style="font-size: 18px; margin-top: 20px">
							根据《国家学生体质健康标准》，将测试对象按照性别和年龄进行精准分类，不同年龄段、不同性别对应不同测试项目和评分标准，测试结果更加科学合理。
						</div>
						<div style="font-size: 28px;margin-top: 40px;">体态标准</div>
						<div style="font-size: 18px; margin-top: 20px">
							通过AI人体关键点识别和人像分割技术，通过正面、侧面观定点照片，比对人体标准体态，提取关键数据，计算出各部位的体态数据。
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="el-img">
						<img src="../assets/img/1.png" alt="" />
					</div>
				</el-col>
			</el-row>
		</div>
		<div style="text-align: center; font-size: 36px;margin-top: 40px;">
			数据记录，深入分析
		</div>
		<div class="cont1">
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="el-img">
						<img src="../assets/img/phy.png" alt="" />
					</div>
				</el-col>
				<el-col :span="12">
					<div class="el-txt">
						<div style="font-size: 28px">报告及时生成</div>
						<div style="font-size: 18px; margin-top: 20px">
							支持手机APP、随时可测，报告即时生成，报告支持下载、打印、及时推送给家长
						</div>
						<div style="font-size: 28px;margin-top: 40px;">运动处方</div>
						<div style="font-size: 18px; margin-top: 20px">
							一键生成运动处方，帮助机构制定系统化教学方案提供有力的支持。
						</div>
						<div style="font-size: 28px;margin-top: 40px;">成长记录</div>
						<div style="font-size: 18px; margin-top: 20px">
							多次测评记录体测情况，选择两次测试对比分析生成对比报告。通过报告观察学员成长情况，制定更有针对性的课程。
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div style="text-align: center; font-size: 36px;margin-top: 40px;">
			操作便捷，多端适用
		</div>
		<div class="cont2">
			<el-row :gutter="20">
				<el-col :span="12">
					<div class="el-txt">
						<div style="font-size: 28px">功能便捷易上手</div>
						<div style="font-size: 18px; margin-top: 20px">
							系统功能操作简单，可以快速上手，系统测试支持批量导入，报告支持批量导出，大大减少人工成本，提高工作效率
						</div>
						<div style="font-size: 28px;margin-top: 40px;">
							多功能、多设备使用
						</div>
						<div style="font-size: 18px; margin-top: 20px">
							支持电脑、手机同时登录，多台设备登录，根据不同场景下操作使用，实现移动办公，数据实时同步。
						</div>
					</div>
				</el-col>
				<el-col :span="12">
					<div class="el-img">
						<img src="../assets/img/pic_operate.png" alt="" />
					</div>
				</el-col>
			</el-row>
		</div>
		<Contant/>
	</div>
	<!-- 新闻动态 -->
</template>

<script>
	import {
		useStore
	} from "vuex";
	import {
		computed,
		ref
	} from "vue";
	import ApplyDialog from "@/components/ApplyDialog.vue";
	import Contant from "@/components/Contant.vue";
	export default {
		name: "Physical",
		components: {
			ApplyDialog,
			Contant
		},
		setup() {
			const state = computed(() => useStore().state.client);
			const dialogFormVisible = ref(false);
			const img = ref([
				// {
				// 	src: require("../assets/img/bg-banner.png")
				// },
				{
					src: require("../assets/img/physical_banner.png")
				},
			]);
			return {
				state,
				dialogFormVisible,
				img
			};
		},
	};
</script>

<style lang="scss" scoped>
	.cont_1 {
		color: #281816;
		margin-top: 80px;
		position: relative;
		padding: 10px;

		.cont1 {
			position: relative;
			margin: auto;
			margin-top: 80px;
			width: 70%;

			.el-txt {
				padding: 150px 80px;
			}

			.el-img {
				float: right;
				padding: 0 60px;
			}
		}

		.cont2 {
			margin: auto;
			margin-top: 60px;
			width: 70%;

			.el-txt {
				float: right;
				padding: 150px 80px;
			}

			.el-img {
				padding: 0 60px;
			}
		}
	}

	.title {
		padding-top: 40px;
		font-size: 36px;
		color: #281816;
		text-align: center;
	}

	.line {
		width: 140px;
		height: 2px;
		background-color: #ff7360;
		position: absolute;
		margin-top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}
</style>
